
table.bigtable td:has(button) {
    padding: 0;
    margin: 0;
    height: 100%;
    line-height: 1.15;
}
table.bigtable tr {
    line-height: 1.15;
}
table.bigtable button {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 24.4px;
    display: block; 
}
table.bigtable button:hover {
    font-weight: normal;
}
table.bigtable thead tr {
    background: lightgrey;
}

table.bigtable tbody tr.mainRow:nth-child(even) {
    background-color: #f4f2f2;
}

table.bigtable td {
    padding: 3px 10px;
}
table.bigtable td.clickable {
    cursor: 'pointer';
}
table.bigtable td.clickable:hover {
    border: 1px solid black;
}

div.table-wrapper {
    width: 100%;
    overflow-x: auto;
    min-height: 400px;
}

tr.no-break, div.no-break, td.no-break, th.no-break {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

tr.detailsRows {
    color: grey;
    font-size: small;
}

tr.detailsRows td:first-child {
    border-left: 1px solid #6d6d6d;
}
tr.detailsRows td:last-child {
    border-right: 1px solid #6d6d6d;
}

.subheader {
    color: grey;
    font-size: small;
}