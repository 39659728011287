div.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007bff;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 1s linear infinite;
    display: inline-block;
}

div.hint {
    font-size: small;
}
  
@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
