
div.OfferContainer {
    display: grid;
    grid-template-columns: 300px 1fr;
    height: 100%;
    text-align: left;

    .menu-placeholder {
        display: none;
    }
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        .menu-placeholder {
            display: block;
            padding: 20px;
            box-sizing: border-box;
        }

    }
}

div.OfferMenu {
    padding: 20px;
    box-sizing: border-box;
    @media (max-width: 768px) {
        display: none;
    }
}

div.OfferContent {
    background-color: #ffffff;
    width: 100%;
}

div.OneLine {
    white-space: nowrap;
    align-items: center;
    line-height: 100%;
    > * {
        display: inline-block;
        vertical-align: top;
    }
}