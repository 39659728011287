button.blinking {
    animation: blink-animation 1s infinite;
}
  
@keyframes blink-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
}
