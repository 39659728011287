table {
    width: 100%;
}

table.asecla-table tr td {
    font-weight: 300;
}

tr.gray {
    background-color: rgb(241, 241, 241);
}