 main {
	display: block;
	margin: 1% 0px;
	padding: 1% 2%;
	background-color: rgba(255, 255, 255, 0.95);
	text-align: center;
    padding: 0px;
}

main h1 {
	margin: 1% auto;
}

section > h1, section > h2, section > h3, section > h4 {
    padding-left: 15px;
}

@media (max-width: 992px) {
	main {
		width: 95%;
		padding: 1.5%;
		margin: 2% auto 2% auto;
	}
}
@media (min-width: 992px) {
	main {
		max-width: 960px;
	}
}
@media (min-width: 1200px) {
	main {
		max-width: 1140px;
	}
}

.header-content > div.header-right {
	text-align: right;
    max-width: 1200px;
    margin: 0 auto;
}

select {
	background-color: white;
	color: rgb(55, 78, 243);
	border: solid 1px rgb(55, 78, 243);
	padding: 0 0.3em;
    
    height: 1.7em;
}
select:disabled {
	color: grey;
	border: solid 1px lightgrey;
}

#existingOrganizationsSelection > select {
	margin-left: 1em;
    border: none;
    height: 1.5em;
}

input[type=number] {
    font-size: 18px;
    background-color: white;
	color: rgb(55, 78, 243);
	border: solid 1px rgb(55, 78, 243);
    height: 27px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    background-color: rgb(55, 78, 243); 
}

button.asecla-button:disabled {
	background-color: darkgray;
}

.sideNote {
	font-size: small;
	color: grey;
}

span.controllsSpace {
    display: inline-block;
    width: 30px;
}

.finalValue {
    font-size: 18px;
}