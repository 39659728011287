[onclick] {
    cursor:pointer;
}

.clickable {
    border: 1px solid #FFFFFF00;
}
.clickable:hover {
    cursor:pointer;
    border: 1px solid black;
}

div.logInOrRegister td {
    width: 33%;
    vertical-align: text-top;
}