.property-container {
    max-width: 600px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.property-row {
    display: flex;
    padding: 10px 15px;
}
.property-row:nth-child(even) {
    background-color: #f8f8f8;
}
.property-name {
    width: 30%;
    font-weight: bold;
    color: #333;
}
.property-value {
    width: 70%;
    color: #666;
}

.asecla-admin, .property-row.asecla-admin:nth-child(even) {
    background-color: rgb(9, 215, 195);
}
